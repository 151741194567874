import React from 'react'
import NavBar from '../components/common/NavBar'
import HorizontalLogoEU from '../assets/images/logos/eu-horizontal.jpg';
import HorizontalLogoFE from '../assets/images/logos/fe-horizontal.jpg';
import HorizontalLogoRP from '../assets/images/logos/rp-horizontal.png';

const Index = () => {
  return (
    <>
      <NavBar />
      <div className="container">
        <article className="page-article">
          <h1 className="mb-5">European Funds</h1>

          <p>
            <strong>Zaven sp. z o.o. </strong>realizuje projekt dofinansowany z Funduszy Europejskich pt.&nbsp; 
            
            <i>Ekspansja międzynarodowa firmy Zaven poprzez udział w targach branżowych oraz działania promocyjne.</i></p>

          <p><strong>Wkład Funduszy Europejskich: </strong>326 700.00 zł</p>

          <div className="eu-project-logos">
            <img src={HorizontalLogoFE} className='fe-logo' alt='fe-logo'/>
            <img src={HorizontalLogoRP} className='rp-logo' alt='rp-logo'/>
            <img src={HorizontalLogoEU} className='eu-project-logo' alt='eu-logo' />
          </div>
        </article>
      </div>
    </>
  )
}

export default Index
